import {connect} from 'vuex-connect';
import AppAuthView from './AuthView.vue';

import {gettersTypes as settingsGettersTypes} from '@/store/modules/settings/getters';

export default connect({
  methodsToEvents: {
    'on-go-sso-click': async function ({getters}) {
      window.location.href = `${getters[settingsGettersTypes.authUrl]}&redirected_from=${
        window.location.origin
      }`;
    },
  },
})('AppAuthView', AppAuthView);
