import axios from '@/api/axios';

const getCurrentUser = (args = undefined) => axios.post('/auth/check', {}, {params: args});

const impersonate = (data) => axios.post('/auth/impersonate', data);

const logout = () => axios.post('/logout');

export default {
  getCurrentUser,
  impersonate,
  logout,
};
